// Import the LitElement base class and html helper function
import { LitElement, html, css } from 'lit-element';
import 'whatwg-fetch'

// Extend the LitElement base class to create our own
export class SimplyBizElement extends LitElement {
    static get styles() {
                   
        return [
            css`h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin-bottom: 15px;
                padding: 0;
                font-weight: normal;
                color: #333;
                line-height: 1.25;
                font-family: "FFMarkWebProRegular", Helvetica, sans-serif;
                margin-bottom: 15px
            }
    
            @media screen and (max-width: 768px) {
    
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    margin-bottom: 10px
                }
            }
    
            @media screen and (max-width: 640px) {
    
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    margin-bottom: 10px
                }
            }
    
            h1 {
                font-size: 3.75rem;
                text-transform: capitalize;
            }
    
            @media screen and (max-width: 768px) {
                h1 {
                    font-size: 2.5rem;
                }
            }
    
            @media screen and (max-width: 640px) {
                h1 {
                    font-size: 1.875rem;
                }
            }
    
            h2 {
                font-size: 2.5rem;
            }
    
            @media screen and (max-width: 768px) {
                h2 {
                    font-size: 1.875rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h2 {
                    font-size: 1.625rem
                }
            }
    
            h3 {
                font-size: 1.875rem;
            }
    
            @media screen and (max-width: 768px) {
                h3 {
                    font-size: 1.625rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h3 {
                    font-size: 1.5rem
                }
            }
    
            h4 {
                font-size: 1.75rem;
            }
    
            @media screen and (max-width: 768px) {
                h4 {
                    font-size: 1.5rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h4 {
                    font-size: 1.375rem
                }
            }
    
            h5 {
                font-size: 1.375rem;
            }
    
            @media screen and (max-width: 768px) {
                h5 {
                    font-size: 1.375rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h5 {
                    font-size: 1.25rem
                }
            }
    
            h6 {
                font-size: 1.25rem
            }
    
            @media screen and (max-width: 768px) {
                h6 {
                    font-size: 1.25rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h6 {
                    font-size: 1.125rem
                }
            }`,
            css` p {
                font-size: 1rem;
                line-height: 1.5
            }`,
            css`a {

                text-decoration: none;
            }`,
            css`
            .margin-lg {
                margin:40px;
            }
            .margin-md {
                margin:20px; 
            }
            .margin-sm {
                margin:10px; 
            }
            .margin-none {
                margin:0px; 
            }
            .margin-lg-top {
                margin-top:40px;
            }
            .margin-md-top {
                margin-top:20px; 
            }
            .margin-sm-top {
                margin-top:10px; 
            }
            .margin-none-top {
                margin-top:0px; 
            }
            .margin-lg-bottom {
                margin-bottom:40px;
            }
            .margin-md-bottom {
                margin-bottom:20px; 
            }
            .margin-sm-bottom {
                margin-bottom:10px; 
            }
            .margin-none-bottom {
                margin-left:0px; 
            }
            .margin-lg-left {
                margin-left:40px;
            }
            .margin-md-left {
                margin-left:20px; 
            }
            .margin-sm-left {
                margin-left:10px; 
            }
            .margin-none-left {
                margin-left:0px; 
            }
            .margin-lg-right {
                margin-right:40px;
            }
            .margin-md-right {
                margin-right:20px; 
            }
            .margin-sm-right {
                margin-right:10px; 
            }
            .margin-none-right {
                margin-right:0px; 
            }
            
            .padding-lg {
                padding:40px;
            }
            .padding-md {
                padding:20px; 
            }
            .padding-sm {
                padding:10px; 
            }
            .padding-none {
                padding:0px; 
            }
            .padding-lg-top {
                padding-top:40px;
            }
            .padding-md-top {
                padding-top:20px; 
            }
            .padding-sm-top {
                padding-top:10px; 
            }
            .padding-none-top {
                padding-top:0px; 
            }
            .padding-lg-bottom {
                padding-bottom:40px;
            }
            .padding-md-bottom {
                padding-bottom:20px; 
            }
            .padding-sm-bottom {
                padding-bottom:10px; 
            }
            .padding-none-bottom {
                padding-left:0px; 
            }
            .padding-lg-left {
                padding-left:40px;
            }
            .padding-md-left {
                padding-left:20px; 
            }
            .padding-sm-left {
                padding-left:10px; 
            }
            .padding-none-left {
                padding-left:0px; 
            }
            .padding-lg-right {
                padding-right:40px;
            }
            .padding-md-right {
                padding-right:20px; 
            }
            .padding-sm-right {
                padding-right:10px; 
            }
            .padding-none-right {
                padding-right:0px; 
            }
            `,
            css`
            @media (min-width: 1200px) {
  
                .build-sb__tiles {
                    column-count: 3;} 
                .build-sb__tile__wrapper { 
                    margin-right: 15px;
                    margin-left: 15px;
                    width: 30%;}
                .build-sb__tile__wrapper__full-width { 		
                    width: 93%;}
                .margin-lg {
                    margin:60px; }
                .margin-md {
                    margin:30px; }
                .margin-sm {
                    margin:15px; }
                .margin-none {
                    margin:0px; }
                .margin-lg-top {
                    margin-top:60px; }
                .margin-md-top {
                    margin-top:30px; }
                .margin-sm-top {
                    margin-top:15px; }
                .margin-none-top {
                    margin-top:0px; }
                .margin-lg-bottom {
                    margin-bottom:60px; }
                .margin-md-bottom {
                    margin-bottom:30px; }
                .margin-sm-bottom {
                    margin-bottom:15px; }
                .margin-none-bottom {
                    margin-left:0px; }
                .margin-lg-left {
                    margin-left:60px; }
                .margin-md-left {
                    margin-left:30px; }
                .margin-sm-left {
                    margin-left:15px; }
                .margin-none-left {
                    margin-left:0px; }
                .margin-lg-right {
                    margin-right:60px; }
                .margin-md-right {
                    margin-right:30px; }
                .margin-sm-right {
                    margin-right:15px; }
                .margin-none-right {
                    margin-right:0px; }
                    
                .padding-lg {
                    padding:40px; }
                .padding-md {
                    padding:20px; }
                .padding-sm {
                    padding:10px; }
                .padding-none {
                    padding:0px; }
                .padding-lg-top {
                    padding-top:40px; }
                .padding-md-top {
                    padding-top:20px; }
                .padding-sm-top {
                    padding-top:10px; }
                .padding-none-top {
                    padding-top:0px; }
                .padding-lg-bottom {
                    padding-bottom:40px; }
                .padding-md-bottom {
                    padding-bottom:20px; }
                .padding-sm-bottom {
                    padding-bottom:10px; }
                .padding-none-bottom {
                    padding-left:0px; }
                .padding-lg-left {
                    padding-left:40px; }
                .padding-md-left {
                    padding-left:20px; }
                .padding-sm-left {
                    padding-left:10px; }
                .padding-none-left {
                    padding-left:0px; }
                .padding-lg-right {
                    padding-right:40px; }
                .padding-md-right {
                    padding-right:20px; }
                .padding-sm-right {
                    padding-right:10px }
                .padding-none-right {
                    padding-right:0px; }
            }
            `,

        ];
    }
}

