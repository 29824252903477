// Import the SimplyBizElement base class and helpers
import { html, css } from 'lit-element';
import { SimplyBizElement } from './base-component';

// Extend the SimplyBizElement base class
class SimplyBizFooter extends SimplyBizElement {
 
    static get properties() { return { 
        logo: { attribute: 'logo' }, 
        links: { type: Array },
        socialmedia: { type: Array }, 
        footercontent: { attribute: 'footercontent' }, 
      };}
      

      constructor() {
        super();
        this.logo = ''; 
        this.links = new Array();
        this.socialmedia = new Array();
        this.footercontent='';
      }

    static get styles() {

        return [
            super.styles,
            css`:host {
                background-color: #78be20;
                border-top: 1px solid #ddd;
                 min-height: 40px;
                 padding: 0 20px;
                 bottom: 0;
                display: flex;
                 align-items: center;
                 flex-direction: column-reverse;
            } 
            @media (min-width: 768px) {
                :host {
                    // flex-direction: row;
                }
            }`,
            css`logo {
                margin-right:3%;
                padding:1% 1% 1% 1%;
            } .logo {
                margin-right:3%;
                padding:1% 1% 1% 1%;
                width:8%;
            }`,
            css`socialmedia {
                margin-right:3%;
                width:8%;
                
            }
            socialmedia > img {
                margin-right:3%;
                width:8%;
                
            }    
            `,
            css`footer-link {
                font-size: medium;
                margin-right: auto;
                padding-bottom: 1.25rem;
                margin-right:1%;
            }
            .footer-link {
                margin-right:0;margin-left:auto;
            }
                .footer-link2 {
                margin-top:10%;
            }
            footer-link:hover {
                text-decoration: underline;
            }
    
            footer-link > * {
                color: white !important;
            }
                .footer {
            
            padding: 5px 0;
            margin-top: 10px;
            width:100%;
            margin-bottom: 10px;
        }    
        `,
        css`

:root {
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px;
  }
  
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
    *,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}



@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }  
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }  
}

.footerLogoTemplatewidth
{
    width:15%;
}
.footerLinkMediaewidth
{
    width:85%;
}
.footercontenttext
{
    text-align: left ;color:white;
    font-size:72%;
}
.footercontenttextcol-md-3-width
{
    width:15%;
}
.footercontenttextcol-md-9-width
{
    width:85%;
}
.footercontenttextmargin
{
  margin-top:-1.4%;
} 
.apipeseprator{
   border-right:2px solid white;
    margin-right:1%;
    margin-left:1%;
}

div > footer-link:last-child > a.apipeseprator:last-child {
  border: 0;
}

 @media (max-width: 360px) {
  
    .footerLinkMediaewidth
    {
      margin-top:3% !important;
    }
    .footerflexdirectioncolumn
    {
      flex-direction:column !important;
      padding-bottom:3% !important;
    }

    .footerLinkspaddingbottom
    {
        padding:1% 0% 2% 0% !important; 
        display:flex !important;
        margin-left:3% !important;
        text-align:left !important;
        flex-direction:column !important;
    }
        .footerreverse {
    flex-direction:column-reverse !important;
    
  }
    .footercontenttext
{
    text-align: center !important;color:white !important;
    font-size:x-small !important;
}

.footercontenttextcol-md-3-width
{
    width:5% !important;
}
.footercontenttextcol-md-9-width
{
    width:95% !important;
}
    footer-link{
    padding-bottom:0.5rem !important;
    }
    .apipeseprator{
    border: 0 !important;
}
    .footerLogoTemplatewidth
{
    width:auto !important;
}
.footerLinkMediaewidth
{
    width:auto !important;
}
    .footercontenttextmargin
  {
    margin-top:-6% !important;
  }
}

@media (max-width: 640px) {
  
    .footerLinkMediaewidth
    {
    margin-top:3% !important;
    }
    .footerflexdirectioncolumn
    {
      flex-direction:column !important;
      padding-bottom:3% !important;
    }

    .footerLinkspaddingbottom
    {
        padding:1% 0% 2% 0% !important; 
        display:flex !important;
        margin-left:2% !important;
        text-align:left !important;
        flex-direction:column !important;
    }
        .footerreverse {
    flex-direction:column-reverse !important;
    
  }
    .footercontenttext
{
    text-align: center !important;color:white;
    font-size:x-small !important;
}

.footercontenttextcol-md-3-width
{
    width:5% !important;
}
.footercontenttextcol-md-9-width
{
    width:95% !important;
}
    footer-link{
    padding-bottom:0.5rem !important;
    }
    .apipeseprator{
    border: 0 !important;
}
    .footerLogoTemplatewidth
{
    width:auto !important;
}
.footerLinkMediaewidth
{
    width:auto !important;
}
    .footercontenttextmargin
  {
  margin-top:-6% !important;
  }
}
  @media (max-width: 320px) {
  
    .footerLinkMediaewidth
    {
      margin-top:3% !important;
    }
    .footerflexdirectioncolumn
    {
      flex-direction:column !important;
      padding-bottom:3% !important;
    }

    .footerLinkspaddingbottom
    {
        padding:1% 0% 2% 0% !important; 
        display:flex !important;
        margin-left:4% !important;
        text-align:left !important;
        flex-direction:column !important;
    }
        .footerreverse {
    flex-direction:column-reverse !important;
    
  }
    .footercontenttext
{
    text-align: center !important;
    color:white !important;
    font-size:x-small !important;
}

.footercontenttextcol-md-3-width
{
    width:5% !important;
}
.footercontenttextcol-md-9-width
{
    width:95% !important;
}
    footer-link{
    padding-bottom:0.5rem !important;
    }
    .apipeseprator{
    border: 0 !important;
}
    .footerLogoTemplatewidth
{
    width:auto !important;
}
.footerLinkMediaewidth
{
    width:auto !important;
}
    socialmedia {
                margin-right:2% !important;
                margin-left :0.6% !important;
            }
  .footercontenttextmargin
  {
  margin-top:-6% !important;
  }

  .footersocialmediamargin
  {
    margin-left:-1.5% !important;
  }
         
}
  @media (max-width: 430px) {
  
    .footerLinkMediaewidth
    {
      margin-top:3% !important;
    }
    .footerflexdirectioncolumn
    {
      flex-direction:column !important;
      padding-bottom:3% !important;
    }

    .footerLinkspaddingbottom
    {
        padding:1% 0% 2% 0% !important; 
        display:flex !important;
        margin-left:3% !important;
        text-align:left !important;
        flex-direction:column !important;
    }
        .footerreverse {
    flex-direction:column-reverse !important;
    
  }
    .footercontenttext
{
    text-align: center !important;color:white !important;
    font-size:x-small !important;
}

.footercontenttextcol-md-3-width
{
    width:5% !important;
}
.footercontenttextcol-md-9-width
{
    width:95% !important;
}
    footer-link{
    padding-bottom:0.5rem !important;
    }
    .apipeseprator{
    border: 0 !important;
}
    .footerLogoTemplatewidth
{
    width:auto !important;
}
.footerLinkMediaewidth
{
    width:auto !important;
}
  .footercontenttextmargin
  {
    margin-top:-6% !important;
  }
}
  
`,

        ];
    }

    render() {
        
        return html`
        <footer class="footer">
    
        <div class="row footerflexdirectioncolumn">
            <div class="col-md-3 footerLogoTemplatewidth" >
                ${this.footerLogoTemplate} 
            </div>
            <div class="col-md-9 footerLinkMediaewidth">
            <div class="row footerreverse">
            <div class="col-md-6 footersocialmediamargin">
               ${this.socialMediaTemplate} 
            </div>
            <div class="col-md-6 footerLinkspaddingbottom" style="text-align:right">
                ${this.footerLinksTemplate}
            </div>
            </div>
            </div>
            
        </div>
        <div class="row footercontenttextmargin">            
        <div class="col-md-3  footercontenttext footercontenttextcol-md-3-width">
        </div>
            <div class="col-md-9  footercontenttext footercontenttextcol-md-9-width">
            ${this.footercontent}
            </div>            
        </div>
    
</footer>
        `;
       
    }

    get footerLogoTemplate() {
        return html`
         
            <img src="${this.logo}" alt="Powered By Nedbank">
        `;
    }

    get footerLinksTemplate() { 
        return html`${this.links.map(link => html`<footer-link><a href="${link.value}">${link.key}</a> <a class="apipeseprator"></a></footer-link>`)}`;
    }

    get socialMediaTemplate() { 
        return html`${this.socialmedia.map(link => html`<socialmedia><a href="${link.value}"><img style="height:25px" src="${link.logo}" alt="Powered By Nedbank"></a></socialmedia>`)}`;
    }
     
}

// Register the new element with the browser.
customElements.define('simplybiz-footer', SimplyBizFooter);
